import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
export default function CircleWithLabel({ borderColor, gradientStart, gradientEnd, label, }) {
    return (_jsxs(StyledCircleWithLabel, Object.assign({}, { borderColor, gradientStart, gradientEnd }, { children: [_jsx("div", { className: "circle" }), _jsx("div", Object.assign({ className: "label" }, { children: label }))] })));
}
const StyledCircleWithLabel = styled.div `
  display: flex;
  align-items: center;
  padding-right: 2.4vw;
  .circle {
    height: 1vw;
    width: 1vw;
    border-radius: 50%;
     border: ${(props) => `1px solid ${props.borderColor};`}
      background: linear-gradient(180deg, ${(props) => props.gradientStart} 0%, ${(props) => props.gradientEnd} 100%);
  }
  .label {
    padding-left: 0.5vw;
    font-family: 'CiscoSansTT Regular';
    font-weight: 400;
    font-size: 1vw;
    line-height: 1.5vw;
    color: #9e9ea2;
    padding-top: 0.3vw;
  }
`;
