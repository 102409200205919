import styled from 'styled-components';
import { Flex } from '@magnetic/flex';
import FloorSelector from './index';

const StyledFloorSelector = styled(FloorSelector)`
  /* Mask fade distance, for use in mask calculations */
  --mask-height: 6vw;

  /* If content exceeds height of container, overflow! */
  overflow-y: auto;

  /* Need to make sure container has bottom space,
  otherwise content at the bottom is always faded out */
  padding-bottom: var(--mask-height);

  /* The CSS mask */

  /* The content mask is a linear gradient from top to bottom */
  --mask-image-content: linear-gradient(
    to bottom,
    black var(--mask-height),
    black calc(100% - var(--mask-height)),
    transparent
  );

  /* Here we scale the content gradient to the width of the container 
  minus the scrollbar width. The height is the full container height */
  --mask-size-content: 100% 100%;

  /* The scrollbar mask is a black pixel */
  --mask-image-scrollbar: linear-gradient(black, black);
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* The width of our black pixel is the width of the scrollbar.
  The height is the full container height */
  --mask-size-scrollbar: 0 100%;

  /* Apply the mask image and mask size variables */
  mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
  mask-size: var(--mask-size-content), var(--mask-size-scrollbar);

  /* Position the content gradient in the top left, and the 
  scroll gradient in the top right */
  mask-position:
    0 0,
    100% 0;

  /* We don't repeat our mask images */
  mask-repeat: no-repeat;
  && {
    font-family: CiscoSansTT Regular;
    font-weight: 400;
    padding: 1.25vw 1.5625vw;
    background-color: #1d2023;
    border-radius: 1vw;
    height: 82vh;
    overflow: auto;
    scrollbar-width: none;
    .action-prompt {
      line-height: 2.4375vw;
      font-size: 1.5vw;
    }
    .description {
      font-size: 0.82vw;
      opacity: 0.5;
      padding-bottom: 0.25vw;
    }
    .floor-row {
      display: flex;
      justify-content: space-between;
      padding-top: 2.125vw;
    }
  }
`;

export const SelectFloorSubTitle = styled.div`
  font-size: 0.82vw;
  opacity: 0.5;
  padding-bottom: 0.25vw;
`;
export const FloorContainer = styled.div`
  padding: 27px;
`;

StyledFloorSelector.defaultProps = FloorSelector.defaultProps;
export default StyledFloorSelector;
