import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useState } from 'react';
import styled from 'styled-components';
import { Flex } from '@magnetic/flex';
import StopWatch from '../../util/stopwatch';
import { isStatusAvailable } from '../../util';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectRoomById, getContext } from '../../../redux/spaces-slice';
import natsclient from '../../../../../store/natsclient';
import { STATUSES } from '../../../floor/util';
const ButtonsContainer = styled.div `
  display: flex;
  justify-content: center;
`;
const LetterM = styled.div `
  font-size: 0.6875vw;
  padding-top: 0.75vw;
`;
const ButtonText = styled.div `
  font-size: 1.6vw;
  padding-left: 0.5vw;
`;
const Button = styled.div `
  cursor: pointer;
  background: ${(props) => props.background};
  border-radius: 0.32vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.4vw;
  fontfamily: 'CiscoSansBold';
  margin: ${(props) => props.margin};
  width: 23.4vw;
`;
const bookRoomHandler = (start, end, roomId, tenantId, setBookRequestSent) => {
    console.log('Clicked :: Book Room Button ');
    const duration = Math.ceil((Math.max(end, start) - start) / 60);
    const currentSiteDomain = window.location.hostname;
    const domainNameFromLocalStorage = localStorage.getItem(`${currentSiteDomain}:configure-module:domain`);
    let domainName = domainNameFromLocalStorage === '""' || domainNameFromLocalStorage === 'null'
        ? 'webex-api-server-jetstreams-test.dnaspaces.io'
        : domainNameFromLocalStorage;
    if (domainName && domainName[0] === `"`) {
        // Remove " characters from localstorage value
        domainName = domainName.slice(0, -1);
        domainName = domainName.substring(1);
    }
    setBookRequestSent(true);
    // Wait for 30s at max to receive HOLD event from receive Jetstreams
    setTimeout(() => setBookRequestSent(false), 30000);
    console.log('Booking request message sent !');
    fetch(`https://${domainName}/v1/rooms/bookings/create`, {
        method: 'POST',
        body: JSON.stringify({
            roomId: roomId,
            tenantId: tenantId,
            duration: duration,
        }),
    })
        .then((res) => res.json())
        .then((data) => console.log('Booking Response data :: ', data))
        .catch((err) => {
        console.log('Booking error :: ', err);
    });
};
const holdRoomHandler = (setIsTimerActive, locationId, floorLocationId, roomId, tenantId, roomName, setHoldRequestSent) => {
    console.log('Clicked :: Hold Room Button ');
    // propogate availabilityStatus as 'Hold'
    const subject = `hold.${locationId}.${floorLocationId}.${roomId}`;
    const message = {
        roomId: roomId,
        roomName: roomName,
        tenantId: tenantId,
        startTime: Math.ceil(Date.now() / 1000),
    };
    console.log('Published HOLD to NATS JETSTREAMS :: ', message);
    setHoldRequestSent(true);
    // Wait for 30s at max to receive HOLD event from receive Jetstreams
    setTimeout(() => setHoldRequestSent(false), 30000);
    natsclient.publishToStream(subject, message);
};
const Buttons = memo(({ isBookable, isHoldable, availabilityStatus, setIsTimerActive, end }) => {
    console.log('Test :: Buttons component rendered...');
    const { t } = useTranslation();
    const start = Date.now() / 1000;
    const contextInfo = useSelector(getContext);
    const richMapId = contextInfo === null || contextInfo === void 0 ? void 0 : contextInfo.richMapId;
    const roomAnalytics = useSelector((state) => selectRoomById(state, richMapId));
    const roomId = roomAnalytics === null || roomAnalytics === void 0 ? void 0 : roomAnalytics.id;
    const tenantId = roomAnalytics === null || roomAnalytics === void 0 ? void 0 : roomAnalytics.tenant;
    const buildingLocationId = contextInfo === null || contextInfo === void 0 ? void 0 : contextInfo.buildingLocationId;
    const floorId = contextInfo === null || contextInfo === void 0 ? void 0 : contextInfo.currentLocationGraphId;
    const roomName = contextInfo === null || contextInfo === void 0 ? void 0 : contextInfo.roomName;
    const [holdRequestSent, setHoldRequestSent] = useState(false);
    const [bookRequestSent, setBookRequestSent] = useState(false);
    if (availabilityStatus === STATUSES.HOLD && holdRequestSent) {
        setHoldRequestSent(false);
        setIsTimerActive(true);
        const timerElement = document.getElementById('hold-timer');
        if (timerElement && timerElement.style) {
            timerElement.style.display = 'block';
        }
    }
    if (availabilityStatus === STATUSES.BOOKED && bookRequestSent) {
        setBookRequestSent(false);
    }
    return (_jsx(ButtonsContainer, { children: _jsxs(Flex, Object.assign({ direction: "vertical" }, { children: [isHoldable && (_jsxs(Button, Object.assign({ onClick: () => isStatusAvailable(availabilityStatus) &&
                        holdRoomHandler(setIsTimerActive, buildingLocationId, floorId, roomId, tenantId, roomName, setHoldRequestSent), margin: "0 0 0 0", background: isStatusAvailable(availabilityStatus)
                        ? 'linear-gradient(180deg, #1d7ff3 0%, #113d7d 100%)'
                        : '#31363E' }, { children: [!holdRequestSent && _jsx(StopWatch, {}), !holdRequestSent && _jsx(LetterM, { children: "m" }), _jsx(ButtonText, { children: holdRequestSent ? 'Processing...' : t('navigator.hold-room') })] }))), isBookable && (_jsx(Button, Object.assign({ onClick: () => isStatusAvailable(availabilityStatus) &&
                        bookRoomHandler(start, end, roomId, tenantId, setBookRequestSent), margin: "0.4vw 0 0 0", background: isStatusAvailable(availabilityStatus)
                        ? 'linear-gradient(180deg, #e7803d 0%, #8b390e 100%)'
                        : '#31363E' }, { children: _jsx(ButtonText, { children: bookRequestSent ? 'Processing...' : t('navigator.book-room') }) })))] })) }));
});
export default Buttons;
