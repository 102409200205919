import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import styled from 'styled-components';
import { Flex } from '@magnetic/flex';
import { Constants } from '../../config';
import { getNextHourInSeconds, getNextQuarterHourInSeconds, isStatusAvailable, } from '../../util';
import { useTranslation } from 'react-i18next';
const BookingSlotButtonsContainer = styled(Flex) `
  padding: 0 6vw;
  margin-top: ${(props) => (props.widgetCount === 0 ? '0vw' : '-3.4vw')};
`;
const Button = styled.div `
  background: ${(props) => props.background};
  width: 4vw;
  height: 4vw;
  text-align: center;
  border-radius: 4vw;
  font-size: 3.4vw;
  font-family: 'CiscoSans Thin';
  cursor: pointer;
`;
const Text = styled.div `
  padding-top: 2.8vw;
  font-family: 'CiscoSans Thin';
`;
const IsMeetingOverlapping = (endTime, meetings) => {
    for (let meeting of meetings) {
        if (meeting.startTime < endTime) {
            return true;
        }
    }
    return false;
};
const bookingSlotMinusHandler = (bookingSlotWidth, setBookingSlotWidth, setBookingSlotActive, timeZone, bookingSlotEndTime, setBookingSlotEndTime, currentTime) => {
    console.log('Clicked :: Booking Slot Minus Button ');
    const nextHour = getNextHourInSeconds(currentTime, timeZone);
    if (bookingSlotEndTime >= nextHour) {
        setBookingSlotWidth(bookingSlotWidth - Constants.SLOT_WIDTH / 4);
        // localStorage.setItem(
        //   'booking-slot-width',
        //   `${bookingSlotWidth - Constants.SLOT_WIDTH / 4}`
        // );
        setBookingSlotEndTime(bookingSlotEndTime - 900);
    }
    else {
        setBookingSlotEndTime(currentTime);
        setBookingSlotActive(false);
        setBookingSlotWidth(0);
        // localStorage.setItem('booking-slot-width', '0');
    }
};
const bookingSlotAddHandler = (bookingSlotWidth, setBookingSlotWidth, setBookingSlotActive, meetings, timeZone, bookingSlotEndTime, setBookingSlotEndTime, currentTime) => {
    console.log('Clicked :: Booking Slot Add Button ');
    const nextHour = getNextHourInSeconds(currentTime, timeZone);
    const nextQuarterHour = getNextQuarterHourInSeconds(bookingSlotEndTime, timeZone);
    const halfSlotDuration = nextHour - currentTime;
    if (nextQuarterHour <= nextHour &&
        !IsMeetingOverlapping(nextQuarterHour, meetings)) {
        setBookingSlotActive(true);
        const newDuration = nextQuarterHour - currentTime;
        const newBookingSlotWidth = (newDuration / halfSlotDuration) * (Constants.SLOT_WIDTH / 2);
        setBookingSlotWidth(newBookingSlotWidth);
        // localStorage.setItem('booking-slot-width', `${newBookingSlotWidth}`);
        setBookingSlotEndTime(nextQuarterHour);
    }
    else if (!IsMeetingOverlapping(bookingSlotEndTime + 900, meetings)) {
        const newDuration = bookingSlotEndTime - currentTime;
        if (newDuration >= 3600 * 9) {
            // If duration exceeds 9hrs, ignore, do not making book API Call
            return;
        }
        setBookingSlotActive(true);
        const newBookingSlotWidth = bookingSlotWidth + Constants.SLOT_WIDTH / 4;
        setBookingSlotWidth(newBookingSlotWidth);
        // localStorage.setItem('booking-slot-width', `${newBookingSlotWidth}`);
        setBookingSlotEndTime(bookingSlotEndTime + 900);
    }
};
const BookingSlotButtons = memo(({ bookingSlotWidth, setBookingSlotWidth, setBookingSlotActive, meetings, timeZone, bookingSlotEndTime, setBookingSlotEndTime, widgetCount, availabilityStatus, }) => {
    const { t } = useTranslation();
    const currentTime = Date.now() / 1000;
    console.log('Test :: Booking Slot Buttons component rendered....');
    return (_jsxs(BookingSlotButtonsContainer, Object.assign({ widgetCount: widgetCount, justify: "space-evenly" }, { children: [_jsx(Button, Object.assign({ onClick: () => isStatusAvailable(availabilityStatus) &&
                    bookingSlotMinusHandler(bookingSlotWidth, setBookingSlotWidth, setBookingSlotActive, timeZone, bookingSlotEndTime, setBookingSlotEndTime, currentTime), background: isStatusAvailable(availabilityStatus) ? '#272a2f' : '#31363E' }, { children: "-" })), _jsx(Text, { children: t('navigator.15-min') }), _jsx(Button, Object.assign({ onClick: () => isStatusAvailable(availabilityStatus) &&
                    bookingSlotAddHandler(bookingSlotWidth, setBookingSlotWidth, setBookingSlotActive, meetings, timeZone, bookingSlotEndTime, setBookingSlotEndTime, currentTime), background: isStatusAvailable(availabilityStatus) ? '#272a2f' : '#31363E' }, { children: "+" }))] })));
});
export default BookingSlotButtons;
