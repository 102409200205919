import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import FloorMapButton from './floor-map-button';
import { adjustMapboxControlPosition, initializeRichMap } from './util';
import { POI_TYPES } from '../../util';
import { useTranslation } from 'react-i18next';
import { useMap } from '../../layout/MapContext';
import DeskHelper from './desk/desk';
import MapContainer from './map-container.styled';
import { RoomSearchPopup } from '../room-search-popup';
import { closeRoomDetailsPopup } from '../room-details-popup';
import { store } from '../../../../../store/store';
import { setRoomDataFromMap, setPOIDataFromMap, setDeskDataFromMap, } from '../../../../../store/uislice';
import { WhereAmISwitchFloor } from '../where-am-i-switch-floor';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getLocation, getContext } from '../../../redux/spaces-slice';
const MapComponent = ({ floorId, richMapAccessToken, mapDetails, roomAvailability, deskStatus, 
// theme = 'DARK_WITHOUT_WORLD_MAP',
showRoomDetailsPopup, showPOI = true, isInteractive = true, isSimulated = false, }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { id: floorLocationId } = location.state || floorId;
    const locationData = useSelector(getLocation);
    const floorLocationData = locationData === null || locationData === void 0 ? void 0 : locationData.children.find((child) => child.id === floorLocationId);
    const currentFloorNumber = Number(floorLocationData === null || floorLocationData === void 0 ? void 0 : floorLocationData.floorNumber);
    const { deviceLocationId } = useSelector(getContext);
    const [mapInit, setMapInit] = useState(null);
    const { mapjs } = useMap();
    const [showSearchButton, setShowSearchButton] = useState(true);
    const [showFindMeButton, setShowFindMeButton] = useState(deviceLocationId !== null);
    const [showResetButton, setShowResetButton] = useState(true);
    // const [deskStatus] = useState<any>(null); //setDeskStatus
    const deskHelperObj = useRef(new DeskHelper()).current;
    const [showRoomSearchPopup, setShowRoomSearchPopup] = useState(false);
    const [showWhereAmISwitchFloor, setShowWhereAmISwitchFloor] = useState(false);
    useEffect(() => {
        if (mapjs) {
            console.log('Loaded Rich Map JS script; mapInit', mapInit, ' mapDetails:', mapDetails);
            const newMapInit = Object.assign({ mapContainer: 'map-v2', token: richMapAccessToken === null || richMapAccessToken === void 0 ? void 0 : richMapAccessToken.accessToken, tenantId: mapDetails.map.tenantId, locationId: mapDetails.map.buildingId, initialZoom: mapDetails.map.zoom, initialPos: [mapDetails.map.long, mapDetails.map.lat], initialPitch: mapDetails.map.pitch, initialBearing: mapDetails.map.bearing, defaultFloor: mapDetails.map.floorNumber, poiLegendHolder: 'poi-switch', hideNavigationControls: true, 
                // theme: (window as any).rMap.Themes[theme],
                useTiles: false }, richMapAccessToken);
            setMapInit(newMapInit);
            initializeRichMap(newMapInit);
            adjustMapboxControlPosition();
            showButtons();
            window.rMap.listen(RichMapEvent.ON_LOAD, () => {
                var _a, _b, _c;
                console.log('Rich Maps ON_LOAD Event...');
                const val = window.rMap.doRoomHighlight;
                console.log('doRoomHighlight :: ', val);
                //checkIfFloorChangeRequired();
                // checkIfModelRequired();
                window.rMap.loaded = true;
                // if ((window as any).rMap.doRoomHighlight) {
                // console.log('... map is ready now, going to highlight rooms!');
                highlightRoom();
                // }
                // if ((window as any).rMap.doDeskHighlight) {
                // console.log('... map is ready now, going to highlight desks!');
                highlightDesk();
                // }
                const features = window.rMap.getFeatures();
                const POIs = {};
                const allRooms = [];
                const allDesks = [];
                const POISNameMap = {};
                for (const type of POI_TYPES) {
                    POIs[type] = [];
                }
                for (const feature of features) {
                    // fid is undefined for most features, hence going to obtain it from the poid itself by splitting
                    POISNameMap[feature.id.split('~')[1]] = {
                        name: (_a = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _a === void 0 ? void 0 : _a.name,
                        sType: (_b = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _b === void 0 ? void 0 : _b.sType,
                        poiId: (_c = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _c === void 0 ? void 0 : _c.fid,
                    };
                    if (feature.properties.type === 'meeting-space' ||
                        feature.properties.type === 'work-space') {
                        const featureObj = {
                            name: feature.id.split('~')[1],
                            floorNumber: Number(feature.properties.lvl),
                            availability: 'Unknown',
                            key: feature.id,
                            capacity: 'Unknown',
                        };
                        if (feature.properties.type === 'meeting-space')
                            allRooms.push(featureObj);
                        if (feature.properties.type === 'work-space')
                            allDesks.push(featureObj);
                    }
                    if (feature.isPOI) {
                        if (feature.properties.type === 'toilets') {
                            POIs['Rest Rooms'].push(feature);
                        }
                        else if (feature.properties.type === 'restaurant') {
                            POIs['Cafeteria'].push(feature);
                        }
                        else if (feature.properties.type === 'help-desk') {
                            POIs['Help Desk'].push(feature);
                        }
                        else if (feature.properties.type === 'child-care') {
                            POIs['Child Care Area'].push(feature);
                        }
                        else if (feature.properties.type === 'stairs') {
                            POIs['Stairs'].push(feature);
                        }
                        else if (feature.properties.type === 'lift') {
                            POIs['Elevators'].push(feature);
                        }
                    }
                }
                store.dispatch(setDeskDataFromMap({ payload: allDesks }));
                store.dispatch(setRoomDataFromMap({ payload: allRooms }));
                store.dispatch(setPOIDataFromMap({
                    payload: { POIs, POISNameMap },
                }));
                // Position 3D model
                checkIfModelRequired();
            });
            window.rMap.listen(RichMapEvent.ON_CLICK, function (e) {
                console.log('Rich Maps ON_CLICK Event...', e);
                if (!isInteractive) {
                    return;
                }
                const featuresNew = window.rMap.getFeaturesAtPoint(e, [
                    POITypes.MEETING_SPACES,
                    POITypes.WORK_SPACES,
                ]);
                // console.log('featuresNew', featuresNew)
                featuresNew.forEach((el) => {
                    //console.log('featureNew', el);
                    const spacesId = el !== undefined ? el.feature.id : null;
                    if (spacesId) {
                        const clickedRoom = mapStatus.roomAvailability[spacesId];
                        //Center focus richMap location
                        focusRichMapLocation(spacesId);
                        showRoomDetailsPopup(spacesId, clickedRoom === null || clickedRoom === void 0 ? void 0 : clickedRoom.location, clickedRoom === null || clickedRoom === void 0 ? void 0 : clickedRoom.floorNumber);
                    }
                });
            });
        }
    }, [mapjs]);
    const mapStatus = useRef({
        loaded: false,
        doRoomHighlight: false,
        doDeskHighlight: false,
        roomAvailability: {},
        deskStatus: {},
        markCurrentPosition: false,
    }).current;
    useEffect(() => {
        if (mapStatus.roomAvailability) {
            const timeout = highlightRoom();
            const timeoutForDesks = highlightDesk();
            return () => {
                clearTimeout(timeout);
                clearTimeout(timeoutForDesks);
            };
        }
    }, []);
    useEffect(() => {
        mapStatus.roomAvailability = roomAvailability || {};
        mapStatus.deskStatus = deskStatus || {};
        mapStatus.doRoomHighlight = true;
        const timeout = highlightRoom();
        const timeoutForDesks = highlightDesk();
        return () => {
            clearTimeout(timeout);
            clearTimeout(timeoutForDesks);
        };
        if (!window.rMap || isSimulated)
            return;
        // if (!mapStatus.loaded) {
        //   mapStatus.doRoomHighlight = true;
        //   console.log('to highlight rooms, map is not ready Yet!!!');
        //   return;
        // }
        // const timeout = highlightRoom();
        // return () => clearTimeout(timeout);
    }, [roomAvailability]);
    useEffect(() => {
        mapStatus.deskStatus = deskStatus || {};
        mapStatus.doDeskHighlight = true;
        const timeout = highlightDesk();
        return () => clearTimeout(timeout);
    }, [deskStatus]);
    useEffect(() => {
        checkIfFloorChangeRequired();
    }, [mapDetails.map.floorNumber]);
    function checkIfModelRequired() {
        if (window.rMap.loaded) {
            if (mapDetails.device.modelWithScreen &&
                mapDetails.map.floorLocation === mapDetails.device.deviceLocation) {
                console.log('Going to position 3D Model');
                mapStatus.markCurrentPosition = true;
                const modelPosition = [mapDetails.device.long, mapDetails.device.lat];
                const modelRotationAngle = [
                    mapDetails.device.xAngle,
                    mapDetails.device.yAngle,
                    mapDetails.device.zAngle,
                ];
                window.rMap.markCurrentPosition(modelPosition, modelRotationAngle);
            }
            // Clear camera position and currrent position if floor does not have device location
            if (!mapDetails.device.modelWithScreen ||
                mapDetails.map.floorLocation !== mapDetails.device.deviceLocation) {
                window.rMap.removeCurrentPosition();
                deskHelperObj.removeCameraPosition();
                mapStatus.markCurrentPosition = false;
            }
        }
    }
    // If floor changes we have to call change floor on map
    function checkIfFloorChangeRequired() {
        if (!window.rMap)
            return;
        if (window.rMap
        // &&
        // floorLocationPrev !== mapDetails.map.floorNumber
        ) {
            console.log('Changing floor to ', mapDetails.map.floorNumber);
            window.rMap.changeFloor(mapDetails.map.floorNumber, true);
            console.log('mapDetails Updated', JSON.stringify(mapDetails), 'Selected Floor', mapDetails.map.floorNumber);
            window.rMap.reset();
            checkIfModelRequired();
        }
        // Focus room if we selected from previous floor search box
        const otherFloorRoom = mapDetails.map.otherFloorRoom;
        if (otherFloorRoom) {
            focusRichMapLocation(otherFloorRoom.richMapId);
            if (showRoomDetailsPopup && isInteractive) {
                showRoomDetailsPopup(otherFloorRoom.richMapId, otherFloorRoom === null || otherFloorRoom === void 0 ? void 0 : otherFloorRoom.location, otherFloorRoom === null || otherFloorRoom === void 0 ? void 0 : otherFloorRoom.floorNumber);
            }
        }
    }
    function focusRichMapLocation(richMapId) {
        if (!window.rMap)
            return;
        if (richMapId) {
            window.rMap.focusFeature(richMapId);
        }
    }
    function highlightRoom() {
        return setTimeout(() => {
            const roomAvailabilityForTimeout = mapStatus.roomAvailability;
            Object.values(roomAvailabilityForTimeout)
                // .filter(
                //   (roomAvailObj: {
                //     availability?: string;
                //     richMapId?: string;
                //     location?: string;
                //   }) => mapDetails.map.floorLocation === roomAvailObj.location
                // )
                .forEach((roomObj) => {
                const { availability = 'Unknown', richMapId = '' } = roomObj;
                // console.log('Going to highlight room ==> ', richMapId, availability);
                try {
                    window.rMap.changeFeatureState({
                        source: 'selected-floor',
                        // sourceLayer: 'maps-layer',
                        id: richMapId,
                    }, FeatureStates[availability.toUpperCase()] // TODO figure out why there is an undefined reference
                    );
                }
                catch (e) {
                    console.log('to highlight rooms, map is not ready Yet!!!');
                }
            });
        }, 500);
    }
    function highlightDesk() {
        return setTimeout(() => {
            const deskStatusForTimeout = mapStatus.deskStatus;
            Object.values(deskStatusForTimeout)
                // .filter(
                //   (deskStatusObj: {
                //     availability?: string;
                //     richMapId?: string;
                //     location?: string;
                //   }) => mapDetails.map.floorLocation === deskStatusObj.location
                // }) => mapDetails.map.floorLocation === Number(deskStatusObj.location)
                // )
                .forEach((deskObj) => {
                const { availability = 'Unknown', richMapId = '' } = deskObj;
                try {
                    window.rMap.changeFeatureState({
                        source: 'selected-floor',
                        // sourceLayer: 'maps-layer',
                        id: richMapId,
                    }, FeatureStates[availability.toUpperCase()] // TODO figure out why there is an undefined reference
                    );
                }
                catch (e) {
                    console.log('to highlight rooms, map is not ready Yet!!!');
                }
            });
        }, 500);
    }
    const showButtons = () => {
        console.log('Buttons showing up, isInteractive:', isInteractive);
        isInteractive && setShowSearchButton(true);
        // isInteractive && setShowFindMeButton(true);
        isInteractive && setShowResetButton(true);
    };
    const handleSearchButtonClick = () => {
        console.log('Search Button clicked');
        // Add your logic here
        setShowRoomSearchPopup(true);
    };
    const handleFindMeButtonClick = () => {
        console.log('FindMe button clicked');
        if (mapDetails.map.floorLocation !== mapDetails.device.deviceLocation) {
            //Non where am I floor case
            setShowWhereAmISwitchFloor(true);
        }
        else {
            // Where am I floor case
            window.rMap.locate();
        }
    };
    const handleResetButtonClick = () => {
        console.log('ResetButton clicked');
        window.rMap.reset();
        window.rMap.exitNavigation();
        closeRoomDetailsPopup();
        // closeRoomDetailsPopup();
    };
    return (_jsxs("div", { children: [showRoomSearchPopup && (_jsx(RoomSearchPopup, { floorId: floorLocationId, onClose: () => setShowRoomSearchPopup(false) })), showWhereAmISwitchFloor && (_jsx(WhereAmISwitchFloor, { currentFloorNumber: currentFloorNumber, switchFloorNumber: Number(mapDetails.device.deviceLocation), selectFloor: (id) => {
                    closeRoomDetailsPopup();
                    navigate('/floor', { state: { id } });
                    setShowWhereAmISwitchFloor(false);
                    setTimeout(() => {
                        window.rMap.locate();
                    }, 1000);
                }, onClose: () => {
                    setShowWhereAmISwitchFloor(false);
                } })), _jsx(MapContainer, { id: "map-v2", className: "map-container", style: {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                } }), isInteractive && (_jsxs(FloorMapButton, { children: [showSearchButton && (_jsx("div", Object.assign({ id: "search_button", onClick: handleSearchButtonClick }, { children: _jsx("img", { src: "static/images/search-floor-map.svg", alt: "Search" }) }))), showFindMeButton && (_jsx("div", Object.assign({ onClick: handleFindMeButtonClick }, { children: _jsx("img", { src: "static/images/where-am-i.svg", alt: "Where am I" }) }))), showResetButton && (_jsx("div", Object.assign({ onClick: handleResetButtonClick }, { children: _jsx("img", { src: "static/images/reset-floor-map.svg", alt: "Reset" }) }))), !showResetButton && (_jsx("div", { children: _jsx("img", { src: "static/images/reset-floor-map-disabled.svg", alt: "Reset Disabled" }) }))] })), isInteractive && showPOI && !isSimulated && window.rMap && (_jsxs("div", Object.assign({ id: "poi-switch", style: { bottom: `4.8vw`, right: `1.0vw`, paddingBottom: '0.4vw' } }, { children: [_jsx("p", Object.assign({ id: "wordStyle1" }, { children: t('floor.map.label-poi-on-this-floor') })), _jsx("p", Object.assign({ id: "wordStyle" }, { children: [t('floor.map.label-poi-select-to-find-on-map'), ':'] }))] })))] }));
};
export default MapComponent;
