import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { Flex } from '@magnetic/flex';
import Close from '../../../../../assets/svgs/close-svg';
import { useTranslation } from 'react-i18next';
import Checked from '../../../../../assets/svgs/checked';
import Minus from '../../../../../assets/svgs/minus';
import People from '../../../../../assets/svgs/people';
import Plus from '../../../../../assets/svgs/plus';
import Search from '../../../../../assets/svgs/search';
import { SwitchFloorPopup } from '../switch-floor-popup';
import { getBgColorByStatus, getBorderColorByStatus, STATUS_COLOR_MAP, } from '../../util/index';
import { useSelector } from 'react-redux';
import { getAllRooms, setRoomDetailsPopUp } from '../../../../../store/uislice';
import { store } from '../../../../../store/store';
import { closeRoomDetailsPopup } from '../room-details-popup';
import { getFloorAnalytics, getLocation } from '../../../redux/spaces-slice';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const CAPACITY_MAX = 20;
const CAPACITY_MIN = 1;
const Text = styled(Flex) `
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
  background: ${(props) => props.background};
  padding: ${(props) => props.padding};
`;
const ShowOnMapToggleContainer = styled(Flex) `
  height: 3.6vw;
  margin-top: 0vw;
  background: #1f2226;
  border-radius: 0.8vw;
  padding-top: 1vw;
`;
const Toggle = styled(Flex) `
  height: 2vw;
  width: 4.2vw;
  text-align: center;
  font-size: 0.76vw;
  padding-top: 0.6vw;
  background: ${(props) => props.background};
  border-radius: ${(props) => props.borderRadius};
  z-index: 100;
  cursor: pointer;
`;
const ToggleContainer = styled(Flex) `
  background: #000000;
  height: fit-content;
  width: fit-content;
  border-radius: 0.6vw;
  margin-top: 0.4vw;
`;
const RoomFinderContainer = styled.div `
  margin-top: 1vw;
  height: fit-content;
  z-index: 99999;
`;
const Line = styled.div `
  background: #9e9ea2;
  height: 0.06vw;
  margin-top: 0.8vw;
`;
const Switch = styled(Flex) `
  width: 3.75vw;
  height: fit-content;
  background: ${(props) => props.isChecked
    ? 'linear-gradient(180deg, #113d7d 0%, #1d7ff3 100%)'
    : '#31363E'};
  border-radius: 1.25vw;
  align-items: center;
  cursor: pointer;
  padding: 0.2vw 0vw;
`;
const Circle = styled(Flex) `
  height: 1.25vw;
  width: 1.25vw;
  margin: 0vw 0.125vw;
  border-radius: 1.25vw;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Capacity = styled(Flex) `
  border-radius: 0.6vw;
  padding: 0.6vw 0vw;
  width: 10vw;
  background: black;
  margin-top: -0.4vw;
`;
const SelectCapacityContainer = styled(Flex) `
  margin-top: 2vw;
`;
const SelectTelepresenseContainer = styled(Flex) `
  margin-top: 1vw;
`;
const SearchBarContainer = styled(Flex) `
  background: black;
  padding: 0.6vw;
  border-radius: 0.6vw;
  margin-top: 1vw;
  cursor: pointer;
`;
const ResultsContainer = styled(Flex) ``;
const Result = styled(Flex) `
  height: fit-content;
  padding: 0.4vw;
  background: ${(props) => props.background};
  width: 100%;
  margin: ${(props) => props.margin};
`;
const AvailableCircle = styled(Flex) `
  width: 0.52vw;
  height: 0.52vw;
  margin-right: 0.375vw;
  background: ${(props) => getBgColorByStatus(props.availability)};
  border: 0.1vw solid ${(props) => getBorderColorByStatus(props.availability)};
  border-radius: 0.6875vw;
`;
const SelectAvailability = ({ t, availability, setAvailability }) => {
    const availabilityStatuses = ['Any', '30', '60', '90'];
    return (_jsxs(ShowOnMapToggleContainer, Object.assign({ direction: "vertical", justify: "space-around" }, { children: [_jsx(Flex, Object.assign({ direction: "vertical" }, { children: _jsx(Text, Object.assign({ fontSize: "0.8vw", color: "#9e9ea2" }, { children: t('floor.room-finder.availability-status') })) })), _jsx(ToggleContainer, Object.assign({ justify: "space-between" }, { children: availabilityStatuses.map((availabilityStatus, i) => (_jsx(Toggle, Object.assign({ direction: "vertical", borderRadius: "0.6vw", background: availability === availabilityStatus
                        ? 'linear-gradient(180deg, #1D7FF3 0%, #113D7D 100%)'
                        : '#000000', onClick: () => setAvailability(availabilityStatus) }, { children: availabilityStatus === 'Any'
                        ? availabilityStatus
                        : `${availabilityStatus}min +` }), i))) }))] })));
};
const SelectCapacity = ({ t, minCapacity, setMinCapacity }) => {
    const PlusHandler = () => {
        if (minCapacity < CAPACITY_MAX) {
            setMinCapacity((minCapacity) => minCapacity + 1);
        }
    };
    const MinusHandler = () => {
        if (minCapacity > CAPACITY_MIN) {
            setMinCapacity((minCapacity) => minCapacity - 1);
        }
    };
    return (_jsxs(SelectCapacityContainer, Object.assign({ justify: "space-between" }, { children: [_jsx(Text, Object.assign({ fontSize: "0.8vw", color: "#9e9ea2" }, { children: t('floor.room-finder.room-capacity') })), _jsxs(Capacity, Object.assign({ justify: "space-evenly" }, { children: [_jsx(Minus, { onClick: MinusHandler }), _jsx(People, {}), _jsxs(Text, Object.assign({ fontSize: "0.8vw" }, { children: [minCapacity, " ", t('floor.room-finder.or-more')] })), _jsx(Plus, { onClick: PlusHandler })] }))] })));
};
const SelectTelepresense = ({ t, telepresense, setTelepresense }) => {
    return (_jsxs(SelectTelepresenseContainer, Object.assign({ justify: "space-between" }, { children: [_jsx(Text, Object.assign({ fontSize: "0.8vw", color: "#9e9ea2" }, { children: t('floor.room-finder.with-telepresense-device') })), _jsx(Switch, Object.assign({ id: "collabDevice_toggle", isChecked: telepresense, onClick: () => setTelepresense(!telepresense), justify: telepresense ? 'end' : 'start' }, { children: _jsx(Circle, { children: _jsx(Checked, {}) }) }))] })));
};
const SearchBar = ({ t, onClick }) => {
    return (_jsxs(SearchBarContainer, Object.assign({ onClick: onClick }, { children: [_jsx(Search, {}), _jsxs(Text, Object.assign({ fontSize: "0.8vw" }, { children: [" \u00A0 ", t('floor.room-finder.search')] }))] })));
};
const Results = ({ t, rooms, availability, telepresense, minCapacity, resultClickHandler, currentFloor, }) => {
    const roomsOnThisFloor = rooms.filter((room) => room.floorNumber === currentFloor);
    const roomsNotOnThisFloor = rooms.filter((room) => room.floorNumber !== currentFloor);
    const showRoomsList = (roomsList) => {
        return roomsList.map((room, i) => (_jsxs(Result, Object.assign({ background: i % 2 && '#171b1d', margin: '0 0 0 -0.4vw', direction: "vertical", onClick: () => resultClickHandler(room.name, room.floorNumber, room.key) }, { children: [_jsx(Text, Object.assign({ fontSize: "0.8vw" }, { children: room.name })), _jsxs(Flex, Object.assign({ style: { fontSize: '0.68vw', marginTop: '0.2vw' }, justify: "space-between" }, { children: [_jsxs(Flex, Object.assign({ width: "7.6vw" }, { children: [_jsx(AvailableCircle, { availability: room.availability }), _jsx(Text, Object.assign({ color: STATUS_COLOR_MAP[room.availability] }, { children: room.availability })), _jsxs(Text, { children: ["\u00A0", room.duration] })] })), _jsxs(Flex, Object.assign({ width: "3.2vw" }, { children: [_jsxs(Text, Object.assign({ color: "#9e9ea2" }, { children: [t('floor.room-finder.capacity'), " "] })), _jsxs(Text, Object.assign({ color: "#00BCEB" }, { children: ["\u00A0", room.capacity] }))] })), _jsxs(Flex, { children: [_jsxs(Text, Object.assign({ color: "#9e9ea2" }, { children: [t('floor.room-finder.floor'), " "] })), _jsxs(Text, { children: ["\u00A0", room.floorNumber] })] })] }))] }), i)));
    };
    return (_jsxs(ResultsContainer, Object.assign({ direction: "vertical" }, { children: [_jsx(Text, Object.assign({ padding: "1vw 0vw", fontSize: "1vw", color: "#9e9ea2" }, { children: t('floor.room-finder.on-this-floor') })), showRoomsList(roomsOnThisFloor), _jsx(Text, Object.assign({ fontSize: "1vw", color: "#9e9ea2", padding: "1vw 0vw" }, { children: t('floor.room-finder.similar-results') })), showRoomsList(roomsNotOnThisFloor)] })));
};
function compareName(room1, room2) {
    const name1 = room1.name.toUpperCase();
    const name2 = room2.name.toUpperCase();
    let comparison = 0;
    if (name1 > name2) {
        comparison = 1;
    }
    else if (name1 < name2) {
        comparison = -1;
    }
    return comparison;
}
const getRichMapIDCapacityMap = (locationData) => {
    const richMapIdCapacityMap = {};
    for (const child of locationData === null || locationData === void 0 ? void 0 : locationData.children) {
        const rooms = child === null || child === void 0 ? void 0 : child.rooms;
        if (!rooms)
            continue;
        for (const room of rooms) {
            richMapIdCapacityMap[room.richMapId] = room.capacity;
        }
    }
    return richMapIdCapacityMap;
};
export const fillAnalyticalInfoToRooms = (globalRoomsData, floorAnalytics, locationData) => {
    const richMapIdCapacityMap = getRichMapIDCapacityMap(locationData);
    const modifiedGlobalRoomsData = [];
    let availableRooms = [];
    let bookedRooms = [];
    let occupiedRooms = [];
    for (const floorLocationId in floorAnalytics) {
        const oneFloorAnalytics = floorAnalytics[floorLocationId];
        availableRooms = availableRooms.concat(oneFloorAnalytics.availabilityInfo.roomInfo.available);
        bookedRooms = bookedRooms.concat(oneFloorAnalytics.availabilityInfo.roomInfo.booked);
        occupiedRooms = occupiedRooms.concat(oneFloorAnalytics.availabilityInfo.roomInfo.occupied);
    }
    for (const room of globalRoomsData) {
        const newRoom = Object.assign({}, room);
        if (availableRooms.includes(room.key)) {
            newRoom.availability = 'Available';
        }
        else if (bookedRooms.includes(room.key)) {
            newRoom.availability = 'Booked';
        }
        else if (occupiedRooms.includes(room.key)) {
            newRoom.availability = 'Occupied';
        }
        newRoom.capacity = richMapIdCapacityMap[room.key]
            ? richMapIdCapacityMap[room.key]
            : 'Unknown';
        modifiedGlobalRoomsData.push(newRoom);
    }
    return modifiedGlobalRoomsData;
};
const RoomFinder = ({ floorId, onClose }) => {
    const navigate = useNavigate();
    const roomsData = [...useSelector(getAllRooms)];
    let globalRoomsData = [...roomsData];
    globalRoomsData.sort(compareName);
    const location = useLocation();
    const locationData = useSelector(getLocation);
    const { id: floorLocationId } = location.state || floorId;
    const floorLocationData = locationData.children.find((child) => child.id === floorLocationId);
    const currentFloor = floorLocationData === null || floorLocationData === void 0 ? void 0 : floorLocationData.floorNumber;
    const { t } = useTranslation();
    const [availability, setAvailability] = useState('Any');
    const [telepresense, setTelepresense] = useState(true);
    const [minCapacity, setMinCapacity] = useState(1);
    const [showSwitchFloorPopup, setShowSwitchFloorPopup] = useState(false);
    const [roomNameForSwitchFloor, setRoomNameForSwitchFloor] = useState(null);
    const [floorNumberForSwitchFloor, setFloorNumberForSwitchFloor] = useState(null);
    const [selectedRichMapId, setSelectedRichMapId] = useState(null);
    const floorAnalytics = useSelector(getFloorAnalytics);
    // Provide availability, capacity info to the rooms for which the info is available
    globalRoomsData = fillAnalyticalInfoToRooms(globalRoomsData, floorAnalytics, locationData);
    useEffect(() => {
        const newRooms = globalRoomsData.filter((room) => room.capacity >= minCapacity // Room finder has a capacity filter, hence choosing not to display non-webex rooms here
        );
        newRooms.sort(compareName);
        setRooms(newRooms);
    }, [availability, telepresense, minCapacity]);
    const [rooms, setRooms] = useState(globalRoomsData);
    const resultClickHandler = (name, floorNuber, key) => {
        setSelectedRichMapId(key);
        if (floorNuber !== currentFloor) {
            setRoomNameForSwitchFloor(name);
            setFloorNumberForSwitchFloor(floorNuber);
            setShowSwitchFloorPopup(true);
            closeRoomDetailsPopup();
        }
        else {
            window.rMap.focusFeature(key);
            store.dispatch(setRoomDetailsPopUp({ payload: key }));
        }
    };
    const enableRoomSearchPopup = () => {
        var _a;
        (_a = document.getElementById('search_button')) === null || _a === void 0 ? void 0 : _a.click();
    };
    const closeSwitchFloorPopup = () => {
        var _a;
        setShowSwitchFloorPopup(false);
        closeRoomDetailsPopup();
        if (document.getElementById('quit-search')) {
            (_a = document.getElementById('quit-search')) === null || _a === void 0 ? void 0 : _a.click();
        }
        setTimeout(() => {
            store.dispatch(setRoomDetailsPopUp({ payload: selectedRichMapId }));
            window.rMap.focusFeature(selectedRichMapId);
        }, 1000);
        setSelectedRichMapId(null);
    };
    return (_jsxs(RoomFinderContainer, { children: [showSwitchFloorPopup && (_jsx(SwitchFloorPopup, { name: roomNameForSwitchFloor, floorNumber: floorNumberForSwitchFloor, selectFloor: (selectedFloorId) => {
                    navigate('/floor', { state: { id: selectedFloorId } });
                    closeSwitchFloorPopup();
                }, onClose: closeSwitchFloorPopup, isPOI: false })), _jsxs(Flex, Object.assign({ justify: "space-between" }, { children: [_jsx(Text, Object.assign({ fontSize: "1.2vw" }, { children: t('floor.counts-widget.room-finder') })), _jsx(Close, { onClose: onClose })] })), _jsx(Line, {}), _jsx(SelectAvailability, { t: t, availability: availability, setAvailability: setAvailability }), _jsx(SelectCapacity, { t: t, minCapacity: minCapacity, setMinCapacity: setMinCapacity }), _jsx(SelectTelepresense, { t: t, telepresense: telepresense, setTelepresense: setTelepresense }), _jsx(SearchBar, { t: t, onClick: enableRoomSearchPopup }), _jsx(Line, {}), _jsx(Results, { t: t, rooms: rooms, availability: availability, telepresense: telepresense, minCapacity: minCapacity, resultClickHandler: resultClickHandler, currentFloor: currentFloor })] }));
};
export default RoomFinder;
