import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState, memo } from 'react';
import styled from 'styled-components';
import { getNextHourInSeconds, getLocaleString } from '../../util/index';
import { Constants } from '../../config';
const MeetingsTimeLineContainer = styled.div `
  width: 100%;
  height: fit-content;
`;
const HoursListContainer = styled.div `
  display: flex;
  flexdirection: row;
  justifycontent: flex-start;
  font-size: 1.14vw;
  margin-top: 1vw;
  color: #878889;
`;
const MeetingsListContainer = styled.div `
  min-height: 4.32vw;
  max-height: 4.32vw;
  display: flex;
  flex-direction: row;
  position: relative;
  border-radius: 0.4vw;
  background-color: #2a2f33;
  overflow-y: visible;
`;
const MeetingSlot = styled.div `
  position: absolute;
  background-color: ${(props) => props.backgroundColor};
  z-index: 99999;
  height: ${(props) => (props.bookingSlot ? '5vw' : '4vw')};
  border-radius: 0.6vw;
  margin: 0.1vw;
  margin-top: ${(props) => (props.bookingSlot ? '-0.6vw' : '0.1vw')};
  border: ${(props) => props.border};
  left: ${(props) => props.left};
  min-width: ${(props) => props.minWidth};
`;
const HourListItem = styled.div `
  min-width: ${Constants.SLOT_WIDTH}vw;
`;
const HoursList = ({ currentTime, timeZoneId }) => {
    let hoursList = [];
    const firstHourLocaleString = getLocaleString(currentTime, timeZoneId);
    hoursList.push(firstHourLocaleString);
    for (let i = 1; i <= 10; i++) {
        const dateObj = new Date(Date.now() + 3600 * 1000 * i); // Convert to milliseconds
        hoursList.push(dateObj.toLocaleString('en-US', {
            hour: 'numeric',
            hour12: true,
            timeZone: timeZoneId,
        }));
    }
    return (_jsx(HoursListContainer, { children: hoursList.map((hourTimeStamp, i) => {
            return _jsxs(HourListItem, { children: ["| \u00A0 ", hourTimeStamp] }, i);
        }) }));
};
const MeetingsList = ({ meetings, availabilityStatus, bookingSlotWidth, currentTime, timeZone, }) => {
    let meetingsList = [];
    let meetingGoingOn = false, currentMeetingStart, currentMeetingEnd;
    for (let meeting of meetings) {
        if (currentTime > meeting.startTime && currentTime < meeting.endTime) {
            meetingGoingOn = true;
            currentMeetingStart = meeting.startTime;
            currentMeetingEnd = meeting.endTime;
            meetingsList.push([meeting.startTime, meeting.endTime, true]);
        }
        else if (currentTime + 10 * 3600 >= meeting.startTime) {
            // meetings starting within the next 10hrs
            meetingsList.push([meeting.startTime, meeting.endTime, false]);
        }
    }
    meetingsList.sort((a, b) => a[0] - b[0]);
    const nextHour = getNextHourInSeconds(currentTime, timeZone);
    return (_jsxs(MeetingsListContainer, { children: [_jsx(MeetingSlot, { backgroundColor: '#BC6131', border: '4px solid #FF8F54', left: `${Constants.SLOT_WIDTH / 2}vw`, minWidth: `${bookingSlotWidth}vw`, bookingSlot: true }), _jsx(MeetingSlot, { backgroundColor: Constants.availabilityColorMap[availabilityStatus], border: `2px solid ${Constants.availabilityColorMapBorder[availabilityStatus]}`, left: "0vw", minWidth: `${Constants.SLOT_WIDTH / 2 - 0.32}vw` }), meetingGoingOn && (_jsx(MeetingSlot, { backgroundColor: Constants.availabilityColorMap.Booked, border: `2px solid ${Constants.availabilityColorMapBorder.Booked}`, left: `${Constants.SLOT_WIDTH / 2}vw`, minWidth: `${Constants.SLOT_WIDTH / 2 + ((currentMeetingEnd - nextHour) / 3600) * Constants.SLOT_WIDTH + 'vw'}` })), meetingsList.map((meeting, i) => {
                return (!meeting[2] &&
                    (meeting[0] <= nextHour ? (_jsx(MeetingSlot, { backgroundColor: Constants.availabilityColorMap.Booked, border: `2px solid ${Constants.availabilityColorMapBorder.Booked}`, left: `${Constants.SLOT_WIDTH / 2 + ((meeting[0] - currentTime) / (nextHour - currentTime)) * (Constants.SLOT_WIDTH / 2) + 'vw'}`, minWidth: `${((nextHour - meeting[0]) / (nextHour - currentTime)) * (Constants.SLOT_WIDTH / 2) + ((meeting[1] - nextHour) / 3600) * Constants.SLOT_WIDTH + 'vw'}` }, i)) : (_jsx(MeetingSlot, { backgroundColor: Constants.availabilityColorMap.Booked, border: `2px solid ${Constants.availabilityColorMapBorder.Booked}`, left: `${Constants.SLOT_WIDTH + ((meeting[0] - nextHour) / 3600) * Constants.SLOT_WIDTH + 'vw'}`, minWidth: `${((meeting[1] - meeting[0]) / 3600) * Constants.SLOT_WIDTH + 'vw'}` }, i))));
            })] }));
};
const MeetingsTimeLine = memo(({ meetings, availabilityStatus, timeZone, bookingSlotWidth }) => {
    console.log('Test :: Meetings timeline component rendered...');
    // const currentTime = Date.now() / 1000;
    const [currentTime, setCurrentTime] = useState(Date.now() / 1000);
    useEffect(() => {
        const timer = setInterval(() => {
            console.log('Update meetings timeline component after 10s');
            setCurrentTime(Date.now() / 1000);
        }, 10000);
        return () => {
            clearInterval(timer);
        };
    }, []);
    return (_jsxs(MeetingsTimeLineContainer, { children: [_jsx(MeetingsList, { meetings: meetings, availabilityStatus: availabilityStatus, bookingSlotWidth: bookingSlotWidth, currentTime: currentTime, timeZone: timeZone }), _jsx(HoursList, { currentTime: currentTime, timeZoneId: timeZone })] }));
});
export default MeetingsTimeLine;
