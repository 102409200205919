import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import styled from 'styled-components';
import { Flex } from '@magnetic/flex';
import { TelemetryWidget } from '../../../common/telemetry-widget';
import { hasValue } from '../../util';
const AnalyticsContainer = styled(Flex) `
  width: 25%;
  // background-color: #111214;
  background-color: black;
  padding: 1.4vw;
  height: 50vh;
`;
const generateWidgets = (iaq, co2, temperature, humidity, ambientNoise) => {
    const widgets = [];
    if (hasValue(iaq)) {
        widgets.push(_jsx(TelemetryWidget, { size: "sm", type: 'IAQ', status: iaq }));
    }
    if (hasValue(co2)) {
        widgets.push(_jsx(TelemetryWidget, { size: "sm", type: 'CO2', status: co2 }));
    }
    if (hasValue(temperature)) {
        widgets.push(_jsx(TelemetryWidget, { size: "sm", type: 'TEMPERATURE', status: temperature }));
    }
    if (hasValue(humidity)) {
        widgets.push(_jsx(TelemetryWidget, { size: "sm", type: 'HUMIDITY', status: humidity }));
    }
    if (hasValue(ambientNoise)) {
        widgets.push(_jsx(TelemetryWidget, { size: "sm", type: 'AMBIENT_NOISE', status: ambientNoise }));
    }
    return widgets;
};
const Analytics = memo(({ iaq, co2, temperature, humidity, ambientNoise }) => {
    const widgets = generateWidgets(iaq, co2, temperature, humidity, ambientNoise);
    console.log('Test :: Analytics component rendered...');
    return (_jsxs(AnalyticsContainer, Object.assign({ direction: "vertical", justify: "space-between" }, { children: [_jsxs(Flex, Object.assign({ style: { padding: '0vw 1vw' }, justify: widgets.length >= 2 ? 'space-between' : 'flex-end' }, { children: [widgets[0], widgets[1]] })), _jsxs(Flex, Object.assign({ style: { padding: '0vw 1vw' }, justify: widgets.length >= 4 ? 'space-between' : 'flex-start' }, { children: [widgets[2], widgets[3]] })), _jsx(Flex, Object.assign({ style: { padding: '0vw 1vw' }, justify: 'flex-start' }, { children: widgets[4] }))] })));
});
export default Analytics;
