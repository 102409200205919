import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import styled from 'styled-components';
import { Flex } from '@magnetic/flex';
import { useTranslation } from 'react-i18next';
const Container = styled(Flex) `
  display: flex;
  align-items: center;
  width: 9.2vw;
  background: linear-gradient(90deg, #24c6dc 0%, #514a9d 92%);
  height: 5.2vh;
  margin-top: 1.2vw;
  border-radius: 1vw;
  padding: 1vw 0.4vw;
  position: absolute;
  right: 1vw;
  z-index: 9999999999;
`;
const Icon = styled.img `
  width: 4vw;
`;
const Text = styled.div `
  font-size: 1vw;
  font-family: 'CiscoSansRegular';
`;
const Step = styled(Flex) `
  margin-top: 1vw;
  width: 104%;
`;
const StepsContainer = styled.div `
  padding: 1vw;
  margin-top: 0.4vw;
  z-index: 9999999999;
  background: linear-gradient(134.57deg, #212428 0%, #1d2024 99.26%);
  opacity: 0.9;
`;
const Button = styled.div `
  background: black;
  padding: 0.4vw 1vw;
  border-radius: 0.2vw;
`;
const HowToUseThis = () => {
    const { t } = useTranslation();
    const [showSteps, setShowSteps] = useState(false);
    return (_jsxs(Container, Object.assign({ direction: "vertical" }, { children: [_jsxs(Flex, Object.assign({ justify: "space-around", onClick: () => setShowSteps(true) }, { children: [_jsxs(Flex, Object.assign({ direction: "vertical", style: { width: '6vw' }, justify: "center" }, { children: [_jsxs(Text, { children: [t('floor.how-do-i'), " "] }), _jsx(Text, { children: t('use-this') })] })), _jsx(Icon, { src: "static/images/how-do-i-use-this.svg", alt: "How to use this" })] })), showSteps && (_jsxs(StepsContainer, { children: [_jsxs(Step, Object.assign({ justify: "space-between" }, { children: [_jsx("img", { style: { width: '2vw', marginRight: '1vw' }, src: require('../../../../../assets/images/pan_n_rotate.png') }), _jsx(Text, Object.assign({ style: { fontSize: '0.52vw' } }, { children: t('floor.message-pan-rotate-zoom') }))] })), _jsxs(Step, Object.assign({ justify: "space-between" }, { children: [_jsx("img", { style: { width: '2vw', marginRight: '1vw' }, src: require('../../../../../assets/images/search.png') }), _jsx(Text, Object.assign({ style: { fontSize: '0.52vw' } }, { children: t('floor.message-use-room-finder') }))] })), _jsxs(Step, Object.assign({ justify: "space-between" }, { children: [_jsx("img", { style: { width: '2vw', marginRight: '1vw' }, src: require('../../../../../assets/images/tap_on_meeting.png') }), _jsx(Text, Object.assign({ style: { fontSize: '0.52vw' } }, { children: t('floor.message-tap-on-meeting-room') }))] })), _jsx(Step, Object.assign({ justify: "center" }, { children: _jsx(Button, Object.assign({ onClick: () => {
                                setShowSteps(false);
                            } }, { children: _jsx(Text, Object.assign({ style: { fontSize: '0.72vw' } }, { children: t('floor.message-ok-got-it') })) })) }))] }))] })));
};
export default HowToUseThis;
