import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import FloorGoTo from '../../../../common/images/floor-goto';
import { Flex } from '@magnetic/flex';
import { WidgetContainer } from '../../common/styled-widget';
import { pxToVw, pxToVh } from '../../../../common/util';
export const Text = styled.div `
  font-family: 'CiscoSansRegular';
  font-size: 20px;
  line-height: 2vw;
  height: 2vw;
  word-wrap: break-word;
`;
const SingleFloorHorizontal = () => {
    return (_jsxs(WidgetContainer, Object.assign({ width: 530, height: 223, Justify: "space-between" }, { children: [_jsx("img", { src: require('../../../../assets/images/floor-single.png'), alt: "Floor Rich Map Icon", style: { width: `'${pxToVw(223)}'`, height: `'${pxToVh(215)}'` } }), _jsxs(Flex, Object.assign({ direction: "verticle", align: "flex-end", justify: "space-between", gap: 24, grow: true, style: { padding: '24px' } }, { children: [_jsx(Text, Object.assign({ style: { width: '250px' } }, { children: "View interactive map, find available conference rooms and more..." })), _jsx(FloorGoTo, {})] }))] })));
};
const SingleFloorVertical = () => {
    return (_jsxs(WidgetContainer, Object.assign({ direction: "verticle", width: 530, height: 753, justify: "space-between" }, { children: [_jsxs(Flex, Object.assign({ direction: "verticle", gap: 24, style: { padding: '24px' } }, { children: [_jsx("img", { src: require('../../../../assets/images/floor-single.png'), alt: "Floor Rich Map Icon", style: {
                            width: `'${pxToVw(223)}'`,
                            height: `'${pxToVh(379)}'`,
                            maxHeight: '379px',
                        } }), _jsx(Text, Object.assign({ style: { width: '400px' } }, { children: "View interactive map, find available conference rooms and more..." }))] })), _jsx(Flex, Object.assign({ gap: 24, style: { padding: '24px' }, reverse: "true" }, { children: _jsx(FloorGoTo, {}) }))] })));
};
const SingleFloor = ({ fullHeight }) => {
    return fullHeight ? _jsx(SingleFloorVertical, {}) : _jsx(SingleFloorHorizontal, {});
};
export default SingleFloor;
