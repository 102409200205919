import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { Flex } from '@magnetic/flex';
import { useTranslation } from 'react-i18next';
import { getShowOnMap, setShowOnMap } from '../../../../../store/uislice';
import { useSelector } from 'react-redux';
import { store } from '../../../../../store/store';
const ShowOnMapToggleContainer = styled(Flex) `
  height: 3.6vw;
  width: 18vw;
  margin-top: 8vw;
  background: #1f2226;
  border-radius: 0.8vw;
  padding-top: 1vw;
  margin-left: 1.4vw;
  z-index: 0;
`;
const Toggle = styled(Flex) `
  height: 1.8vw;
  width: 4vw;
  text-align: center;
  font-size: 0.72vw;
  padding-top: 0.6vw;
  background: ${(props) => props.background};
  border-radius: ${(props) => props.borderRadius};
  z-index: 100;
  cursor: pointer;
`;
const ToggleContainer = styled(Flex) `
  background: #000000;
  height: fit-content;
  width: fit-content;
  border-radius: 0.6vw;
`;
const Text = styled.div `
  font-size: 0.8vw;
`;
const ShowOnMapToggle = () => {
    const { t } = useTranslation();
    const toggleValue = useSelector(getShowOnMap);
    const toggleAllHandler = () => {
        store.dispatch(setShowOnMap({ payload: 'All' }));
    };
    const toggleRoomsHandler = () => {
        store.dispatch(setShowOnMap({ payload: 'Rooms' }));
    };
    const toggleDesksHandler = () => {
        store.dispatch(setShowOnMap({ payload: 'Desks' }));
    };
    return (_jsxs(ShowOnMapToggleContainer, Object.assign({ justify: "space-around" }, { children: [_jsxs(Flex, Object.assign({ direction: "vertical" }, { children: [_jsx(Text, { children: t('floor.show-on-map-toggle.show-on') }), _jsx(Text, { children: t('floor.show-on-map-toggle.map') })] })), _jsxs(ToggleContainer, Object.assign({ justify: "space-between" }, { children: [_jsx(Toggle, Object.assign({ direction: "vertical", borderRadius: "0.6vw", background: toggleValue === 'All'
                            ? 'linear-gradient(180deg, #1D7FF3 0%, #113D7D 100%)'
                            : '#000000', onClick: () => toggleAllHandler() }, { children: t('floor.show-on-map-toggle.all') })), _jsx(Toggle, Object.assign({ direction: "vertical", borderRadius: "0.6vw", background: toggleValue === 'Rooms'
                            ? 'linear-gradient(180deg, #1D7FF3 0%, #113D7D 100%)'
                            : '#000000', onClick: () => toggleRoomsHandler() }, { children: t('floor.show-on-map-toggle.rooms') })), _jsx(Toggle, Object.assign({ direction: "vertical", borderRadius: "0.6vw", background: toggleValue === 'Desks'
                            ? 'linear-gradient(180deg, #1D7FF3 0%, #113D7D 100%)'
                            : '#000000', onClick: () => toggleDesksHandler() }, { children: t('floor.show-on-map-toggle.desks') }))] }))] })));
};
export default ShowOnMapToggle;
