import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import styled from 'styled-components';
import { Flex } from '@magnetic/flex';
import Footer from '../../../common/footer';
const FooterContainer = styled(Flex) `
  height: 100%;
  margin-top: 1vw;
`;
const NavigatorFooter = memo(() => {
    console.log('Test :: Footer rendered...');
    return (_jsx(FooterContainer, Object.assign({ direction: "vertical", justify: "flex-end" }, { children: _jsx(Footer, {}) })));
});
export default NavigatorFooter;
